.headerComputer {
    display: none;
}

@media screen and (min-width: 771px) {

    .headerComputer {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--HeaderHeight);

        z-index: 100;

        display: grid;
        grid-template-columns: 1fr 3fr 4fr;
        grid-template-rows: 1fr;

        background-color: var(--colorPrincipal);
    }

    .logoContainer{
        grid-area: 1 / 1 / 2 / 2;
        
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    }

    .computerMenu {

        grid-area: 1 / 3 / 2 / 4;

        font-family: var(--exo2);
        font-size: calc(1em + 1vw);
        font-weight: 400;

        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
    }

    .computerMenu a{
        text-decoration: none;
        color: var(--negro);
        font-weight: 400;
    }
}

