.logoMenu {
    width: var(--HeaderHeight);
    height: var(--HeaderHeight);

    background-color: inherit;
    border-style: none;
    border-color: var(--negro);
    padding: 0.5rem;
    margin: 0;
}

.logoMenu img {
    max-width: 100%;
    max-height: 100%;
}