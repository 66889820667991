@keyframes openMenu {
    from {
        height: 0;
        opacity: 0;
    }

    to {
        height: 40vh;
        opacity: 1;
    }
}


.headerMobile {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--HeaderHeight);

    z-index: 100;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    background-color: var(--colorPrincipal);
}

#logoMenuMobile{
    margin-left: 1rem;
}

.hamburguerIcon {
    width: fit-content;
    height: fit-content;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin-right: 1.5rem;

    background-color: inherit;
    border-style: none;
    border-color: var(--negro);
}

.mobileMenu {
    position: sticky;
    top: var(--HeaderHeight);
    left: 0;
    z-index: 100;

    height: 40vh;

    padding: 10px 0 20px 10px;
    overflow: hidden;
    background-color: var(--blanco);

    font-family: var(--exo2);
    font-size: calc(1.5em + 1vw);
    font-weight: 400;

    animation-name: openMenu;
    animation-duration: 0.5s;
    -moz-animation-name: openMenu;
    -moz-animation-duration: 0.5s;
}

.mobileMenu li {
    margin-top: 5vh;
}

.mobileMenu a {
    text-decoration: none;
    color: var(--negro);
    font-weight: 400;
}

.mobileMenu a:active {
    text-decoration: dashed;
}


@media screen and (min-width: 771px) {

    .headerMobile,
    .mobileMenu {
        display: none !important;
    }
}