/* The typing effect */
@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: var(--colorSecundario, #98D6FF);
    }
}

@keyframes flechaAbajo{
    from , to{
        margin-bottom: 20vh;
        width: 80px;
        height: 80px;
    }

    50%{
        margin-bottom: 15vh;
        width: 90px;
        height: 90px;
    }
}

.presentacionContainer {
    width: 100%;
    height: var(--ContentHeight);
    position: relative;
    background-color: var(--negro);

}

.presentacionContainer img {
    height: var(--ContentHeight);
    width: 100%;
    object-fit: cover;
    opacity: 0.9;
}

.bienvenidaSquare {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    height: 10vh;

    position: absolute;
    top: calc(52vh - 5vh);

    background-color: var(--colorPrincipal);
    border-radius: 10px;
}

.bienvenidaSquare span {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid var(--colorSecundario, #98D6FF); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .1em; /* Adjust as needed */
    font-family: var(--exo2);
    font-size:  calc(1.7em + 1vw);
    font-weight: 400;
    color: var(--negro);
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;
  }

@media screen and (min-width: 771px) {
.bienvenidaSquare span{
    font-family: var(--exo2);
    font-size:  calc(1.5em + 1vw);
    font-weight: 400;
    color: var(--negro);
}
}


.flechaAbajo{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
}

.flechaAbajo .icono{
    margin-bottom: 20vh;
    width: 80px;
    height: 80px;
    animation: flechaAbajo 4s linear infinite;
}

.bienvenidaSquare {
    width: 70vw;
    left: calc(50vw - 35vw);
}


@media screen and (min-width: 771px) {
    .bienvenidaSquare {
        width: 30vw;
        left: calc(50vw - 15vw);
    }

}

.seccionHome{
    padding: 2rem 0;
}


@media screen and (min-width: 771px) {
    .seccionHome{
        padding: 3rem 0;
    }
    .seccionHome h2, .seccionHome p{
        margin: 1rem 15rem;
    }
}

.proyectos{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 1rem;
    text-align: center;
    margin: 2rem 0;
}

@media screen and (min-width: 771px) {
    .proyectos{
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        margin: 2rem 15rem;
    }
}

.proyecto{
    width: 100%;
    background-color: var(--colorSecundario);
    border: none;
    border-radius: 10px;
    height: 20vh;
    position: relative;
}

.proyecto a{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.proyecto img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}