footer{
    width: 100%;
    height: fit-content;
    min-height: var(--HeaderHeight);

    background-color: var(--gris);

    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

@media screen and (min-width: 771px) {
    footer{
        justify-content: center;
    }

    footer a{
        margin:0 1rem;
    }
}

footer a{
    color: var(--blanco);
}