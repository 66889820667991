
.novelasContainer{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
}

@media screen and (min-width: 771px) {
    .novelasContainer{
        grid-template-columns: 3fr 7fr;
        grid-template-rows: auto;
    }
}

.textoNovelas{
    grid-area: 1 / 1 / 2 / 2;
    text-align: justify;
}

@media screen and (min-width: 771px) {
    .textoNovelas
    {
        grid-area: 1 / 2 / 2 / 3;
    }
}

.imagenNovelas{
    grid-area: 2 / 1 / 3 / 2;
}

@media screen and (min-width: 771px) {
    .imagenNovelas{
        grid-area: 1 / 1 / 2 / 2;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-start;
        padding-top: 1.5rem;
    }
}

.imagenNovelas img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.botonConAnchor{
    color: var(--negro);
}

.botonesNovelas{
    margin-bottom: 15px;
}