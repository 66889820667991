@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&family=Exo+2:wght@400;700&family=Poppins&display=swap');


:root {
  --colorPrincipal: #ffaf79;
  --colorSecundario: #98D6FF;
  --blanco: #ffffff;
  --negro: #000000;
  --gris: #606060;

  --exo2: 'Exo 2', sans-serif;
  --poppins: 'Poppins', sans-serif;
  --amiri: 'Amiri', serif;

  --HeaderHeight: 8vh;
  --ContentHeight: calc(100vh - var(--HeaderHeight));
}

/*
RESET
*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}


/*
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
*/



html {
  height: 100%;
  font-size: 16px;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  min-width: 100vw;
}

button {
  cursor: pointer;
}

.pagina {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

h1,
h2,
h3,
h4,
p {
  margin: 1rem;
  line-height: 2rem;
}

h1 {
  font-family: var(--exo2);
  font-size: calc(1.7em + 1vw);
  font-weight: 700;
}

h2,
h3,
h4 {
  font-family: var(--poppins);
  font-weight: 400;
}

h2 {
  font-size: calc(1.5em + 1vw);
}

h3 {
  font-size: calc(1.3em + 1vw);
}

h4 {
  font-size: calc(1.1em + 1vw);
}

p {
  font-family: var(--amiri);
  font-size: calc(1.1em + 1vw);
  font-weight: 400;
}

a {
  text-decoration: none;
  color: var(--colorSecundario);
  font-weight: 700;
}

strong {
  font-weight: 700;
}

@media screen and (min-width: 771px) {
  h1 {
    font-size: calc(1.6em + 1vw);
  }

  h2 {
    font-size: calc(1.3em + 1vw);
  }

  h3 {
    font-size: calc(1.2em + 1vw);
  }

  h4 {
    font-size: calc(1em + 1vw);
  }

  p {
    font-family: var(--amiri);
    font-size: calc(0.4em + 1vw);
    font-weight: 400;
  }
}

hr{
  height: 2px;
  border: none;
  background-color: var(--colorPrincipal);
  width: 35%;
}

button{
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--colorSecundario);
  border-radius: 10px;
  font-family: var(--poppins);
  font-size: calc(1.2em + 1vw);
  font-weight: 700;
  color: var(--negro);
  box-shadow: 10px solid var(--gris);
}

@media screen and (min-width: 771px) {
  button{
    font-size: calc(0.2em + 1vw);
    margin: 1rem;
  }
}

main{
  width: calc(100% - 3rem);
  padding: 1.5rem;
  text-align: center;
}

@media screen and (min-width: 771px) {
  main{
    width: calc(100vw - 6rem);
    padding: 3rem;
  }
}