.listaProyectosContainer{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.proyectoContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-top: 1rem;
}

.proyectoContainer:first-of-type{
    margin-top: 0;
}

@media screen and (min-width: 771px) {
    .proyectoContainer{
        grid-template-columns: 2fr 8fr;
        grid-template-rows: auto;
    }
}

.proyectoTexto{
    grid-area: 1 / 1 / 2 / 2;
}

.proyectoTexto p  {
    text-align: justify;
}

@media screen and (min-width: 771px) {
    .proyectoTexto{
        grid-area: 1 / 2 / 2 / 3;
    }

    .proyectoTexto h2{
        text-align: justify;
    }
}

.proyectoImagen{
    grid-area: 2 / 1 / 3 / 2;
}

@media screen and (min-width: 771px) {
    .proyectoImagen{
        grid-area: 1 / 1 / 2 / 2;
    }
}

.proyectoImagen img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}